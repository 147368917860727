import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/vercel/path0/volya-frontend/app/contexts/AuthContext.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/volya-frontend/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["CSPostHogProvider"] */ "/vercel/path0/volya-frontend/app/posthog-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/volya-frontend/components/ui/sonner.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/volya-frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/volya-frontend/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/GeistVF.woff\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"geistSans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/volya-frontend/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/GeistMonoVF.woff\",\"variable\":\"--font-geist-mono\",\"weight\":\"100 900\"}],\"variableName\":\"geistMono\"}");
