'use client'

import { createContext, useContext, useEffect, useState } from 'react'
import { createClient } from '@/app/utils/supabase/clients';
import { User } from '@supabase/supabase-js';
import posthog from 'posthog-js'


type AuthContextType = {
  user: unknown
  loading: boolean
  userDetails: unknown
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  loading: true,
  userDetails: null,
})

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null)
  const [loading, setLoading] = useState(true)
  const [userDetails, setUserDetails] = useState<unknown>(null)
  const supabase = createClient();

  useEffect(() => {
    // Get initial session
    supabase.auth.getSession().then(({ data: { session } }) => {
      setUser(session?.user ?? null)
      if (session?.user) {
        fetchUserDetails(session.user.id)
        // Identify user in PostHog
        posthog.identify(session.user.id, {
          email: session.user.email,
        })

      } else {
        setLoading(false) // Important: Set loading to false even when no user
        posthog.reset() // Reset PostHog identification when no user
      }
    })

    // Listen for auth changes
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setUser(session?.user ?? null)
      if (session?.user) {
        fetchUserDetails(session.user.id)
        posthog.identify(session.user.id, {
          email: session.user.email,
          last_sign_in: new Date().toISOString(),
        })
      }
      else {
        setUserDetails(null)
        posthog.reset() // Reset PostHog identification when user signs out
      }
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [])

  async function fetchUserDetails(userId: string) {
    try {
      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('id', userId)
        .single()

      if (error) throw error
      setUserDetails(data)
    } catch (error) {
      console.error('Error fetching user details:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <AuthContext.Provider value={{ user, loading, userDetails }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}